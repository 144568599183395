<template>
  <div style="height: calc(100% - 63px)">
    <el-row class="sale_content">
      <el-col :span="9" class="project_left">
        <div class="pad_15">
          <el-input placeholder="请输入商品名称、别名关键字，按回车搜索" prefix-icon="el-icon-search" v-model="goodsName" clearable size="small" @keyup.enter.native="searchGoodsClick" @clear="clearClick">
            <el-select v-model="typeIndex" slot="prepend" style="width: 100px;">
              <el-option label="全部" value="0"></el-option>
              <el-option label="项目" value="1"></el-option>
              <el-option label="产品" value="2"></el-option>
            </el-select>
          </el-input>
        </div>
        <el-tabs v-model="tabPane">
          <el-tab-pane label="全部" name="0">
            <el-row class="category_project">
              <el-col :span="6" class="border_right text_center category">
                <el-scrollbar class="el-scrollbar_height">
                  <div :class="[goodsType== 'Project'?'category_select':'','pad_10_0','border_bottom','cursor_pointer']" @click="goodsCategoryChange('Project')" v-if="goodsList.Project!=undefined && goodsList.Project.length>0">项目</div>
                  <div :class="[goodsType== 'Product'?'category_select':'','pad_10_0','border_bottom','cursor_pointer']" @click="goodsCategoryChange('Product')" v-if="goodsList.Product!=undefined && goodsList.Product.length>0">产品</div>
                </el-scrollbar>
              </el-col>
              <el-col :span="18" class="project">
                <div class="producct_list">
                  <el-scrollbar class="el-scrollbar_height">
                    <div v-for="product in goodsAll" :key="product.ID" class="border_bottom pad_5_10 cursor_pointer" @click="goodsChange(product)">
                      <div>
                        <span>{{product.Name}}</span>
                        <span v-if="product.Alias">({{product.Alias}})</span>
                      </div>
                      <div>
                        <span class="color_red">¥ {{product.Price | NumFormat}}</span>
                        <span class="marlt_10 font_12 color_gray" v-if="product.LargessPrice">赠送：¥ {{product.LargessPrice | NumFormat}}</span>
                        <span class="font_12 color_gray" style="float:right">{{product.ValidDayName}}</span>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="项目" v-if="IsExistProject" name="1">
            <el-row class="category_project">
              <el-col :span="6" class="border_right text_center category">
                <el-scrollbar class="el-scrollbar_height">
                  <div v-for="(item,index) in projectCategory" :key="index" :class="[projectCategoryIndex== index?'category_select':'','pad_10_0','border_bottom','cursor_pointer']" @click="projectCategoryChange(item,index)">{{item.Name}}</div>
                </el-scrollbar>
              </el-col>
              <el-col :span="18" class="project">
                <div class="project_list">
                  <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
                    <!-- <el-collapse v-model="collapseName" > -->
                    <el-collapse accordion>
                      <el-collapse-item v-for="(item,index) in projectList" :key="index" :title="item.Name" :id="`inclode${index}`" :name="item.ID">
                        <div v-for="project in item.Project" class="border_bottom pad_5_10 cursor_pointer" :key="project.ID" @click="projectChange(project)">
                          <div>
                            <span>{{project.Name}}</span>
                            <span v-if="project.Alias">({{project.Alias}})</span>
                          </div>
                          <div class="color_red">¥ {{project.Price | NumFormat}}</div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="产品" v-if="IsExistProduct" name="2">
            <el-row class="category_project">
              <el-col :span="6" class="border_right text_center category">
                <el-scrollbar class="el-scrollbar_height">
                  <div v-for="(item,index) in productCategory" :key="index" :class="[productCategoryIndex== index?'category_select':'','pad_10_0','border_bottom','cursor_pointer']" @click="productCategoryChange(item,index)">{{item.Name}}</div>
                </el-scrollbar>
              </el-col>
              <el-col :span="18" class="project">
                <div class="producct_list">
                  <el-scrollbar class="el-scrollbar_height">
                    <!-- <el-collapse v-model="productCollapseName"> -->
                    <el-collapse accordion>
                      <el-collapse-item v-for="(item,index) in productList" :key="index" :title="item.Name" :id="`inclode${index}`" :name="item.ID">
                        <div v-for="product in item.Product" class="border_bottom pad_5_10 cursor_pointer" :key="product.ID" @click="productChange(product)">
                          <div>
                            <span>{{product.Name}}</span>
                            <span v-if="product.Alias">({{product.Alias}})</span>
                          </div>
                          <div class="color_red">¥ {{product.Price | NumFormat}}</div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>

                    <!-- <div v-for="product in productList" :key="product.ID" class="border_bottom pad_5_10 cursor_pointer" @click="productChange(product)">
                      <div>
                        <span>{{product.Name}}</span>
                        <span v-if="product.Alias">({{product.Alias}})</span>
                      </div>
                      <div class="color_red">¥ {{product.Price | NumFormat}}</div>
                    </div> -->
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="15" class="project_right">
        <el-container style="height: 100%">
          <el-main>
            <el-scrollbar class="el-scrollbar_height">
              <!--项目-->
              <div v-if="selectProject.length>0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="7">项目</el-col>
                    <el-col :span="7">数量</el-col>
                    <el-col :span="7">金额</el-col>
                    <el-col :span="2">赠送</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom" v-for="(item,index) in selectProject" :key="index + 'sel_Poj'">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="7">
                        <div>
                          <span>{{item.Name}}</span>
                          <span v-if="item.Alias">({{item.Alias}})</span>
                        </div>
                      </el-col>
                      <el-col :span="7">
                        <el-input-number :min="1" size="mini" style="width: 100px" v-model="item.number" @change="numberChange(item)"></el-input-number>
                      </el-col>
                      <el-col :span="7">
                        <span class="marrt_10">{{parseFloat(item.TotalAmount) | NumFormat}}</span>
                        <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="savingCardDeductionClick(1,selectProject,item,index)" v-if="!item.IsLargess"></el-button>
                      </el-col>
                      <el-col :span="2">
                        <el-checkbox v-show="item.IsAllowLargess" v-model="item.IsLargess" @change="largessChange(item)"></el-checkbox>
                      </el-col>
                      <el-col :span="1" :offset="item.IsAllowLargess?0:2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(1,index,item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top:4px">
                      <el-col :span="4">
                        <div class="color_red">¥ {{item.Price | NumFormat}}</div>
                      </el-col>
                      <el-col :span="20">
                        <div class="text_right">
                          <span class="font_12">支付金额：{{(item.IsLargess? 0: item.PayAmount) | NumFormat}}</span>
                          <span class="color_gray font_12 marlt_15" v-if="item.discountPrice !=0">
                            手动改价：
                            <span class="color_red" v-if="item.discountPrice>0">-{{item.discountPrice | NumFormat}}</span>
                            <span class="color_green" v-else>+{{mathAbsData(item.discountPrice) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDiscountPrice>0">
                            卡优惠：
                            <span class="color_red">-{{parseFloat(item.CardDiscountPrice) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDeductionAmount>0">
                            卡抵扣：
                            <span class="color_red">-{{parseFloat(item.CardDeductionAmount) | NumFormat}}</span>
                          </span>
                        </div>
                      </el-col>
                    </el-col>
                  </el-col>
                  <!-- 销售经手人 -->
                  <el-col v-if="item.saleHandlerList.length>0" :span="24" class="border_bottom pad_10 padbm_0 ">
                    <el-row class="cursor_pointer" v-for="(handler,pIndex) in item.saleHandlerList" :key="pIndex + 'sale_poj'" @click.native="employeeHandleClick(1,selectProject,item,index,true)" type="flex" align="top">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handler.Employee,employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color:#909399;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                  <!-- 消耗经手人 -->
                  <el-col v-if="item.consumeHandlerList.length>0" :span="24" class="border_bottom pad_10 padbm_0">
                    <el-row class="cursor_pointer" v-for="(handler,pIndex) in item.consumeHandlerList" :key="pIndex + 'consume_poj'" @click.native="employeeHandleClick(1,selectProject,item,index,false)" type="flex" align="top">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handler.Employee,employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color:#909399;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--产品-->
              <div v-if="selectProduct.length>0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="7">产品</el-col>
                    <el-col :span="7">数量</el-col>
                    <el-col :span="7">金额</el-col>
                    <el-col :span="2">赠送</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom" v-for="(item,index) in selectProduct" :key="index + 'sel_prd'">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="7">
                        <div>
                          <span>{{item.Name}}</span>
                          <span v-if="item.Alias">({{item.Alias}})</span>
                        </div>
                      </el-col>
                      <el-col :span="7">
                        <el-input-number :min="1" size="mini" style="width: 100px" v-model="item.number" @change="numberChange(item)"></el-input-number>
                      </el-col>
                      <el-col :span="7">
                        <span class="marrt_15">{{parseFloat(item.TotalAmount) | NumFormat}}</span>
                        <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="savingCardDeductionClick(2,selectProduct,item,index)" v-if="!item.IsLargess"></el-button>
                      </el-col>
                      <el-col :span="2" v-show="item.IsAllowLargess">
                        <el-checkbox v-model="item.IsLargess" @change="largessChange(item)"></el-checkbox>
                      </el-col>
                      <el-col :span="1" :offset="item.IsAllowLargess?0:2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(2,index,item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px;">
                      <el-col :span="4">
                        <div class="color_red">¥ {{item.Price | NumFormat}}</div>
                      </el-col>
                      <el-col :span="20">
                        <div class="text_right font_12">
                          <span class="font_12">支付金额：{{(item.IsLargess? 0: item.PayAmount) | NumFormat}}</span>
                          <span class="color_gray font_12 marlt_15" v-if="item.discountPrice !=0">
                            手动改价：
                            <span class="color_red" v-if="item.discountPrice>0">-{{item.discountPrice | NumFormat}}</span>
                            <span class="color_green" v-else>+{{mathAbsData(item.discountPrice) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDiscountPrice>0">
                            储值卡优惠：
                            <span class="color_red">- {{item.CardDiscountPrice | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDeductionAmount>0">
                            储值卡抵扣：
                            <span class="color_red">- {{item.CardDeductionAmount | NumFormat}}</span>
                          </span>
                        </div>
                      </el-col>
                    </el-col>
                  </el-col>
                  <!-- 销售经手人 -->
                  <el-col v-if="item.saleHandlerList.length>0" :span="24" class="border_bottom pad_10 padbm_0">
                    <el-row class="cursor_pointer" v-for="(handler,pIndex) in item.saleHandlerList" :key="pIndex + 'sale_poj'" @click.native="employeeHandleClick(2,selectProduct,item,index,true)" type="flex" align="top">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handler.Employee,employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color:#909399;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                  <!-- 消耗经手人 -->
                  <el-col v-if="item.consumeHandlerList.length>0" :span="24" class="border_bottom pad_10 padbm_0">
                    <el-row class="cursor_pointer" v-for="(handler,pIndex) in item.consumeHandlerList" :key="pIndex + 'consume_poj'" @click.native="employeeHandleClick(2,selectProduct,item,index,false)" type="flex" align="top">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handler.Employee,employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color:#909399;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>

                </el-row>
              </div>
            </el-scrollbar>
          </el-main>
          <el-footer class="border_top">
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <span class="font_14 color_maroon">待收款金额：¥ {{PayAmount | NumFormat}}</span>
              </el-col>
              <el-col :span="15">
                <el-input type="textarea" :rows="1" placeholder="请输入备注信息" v-model="Remark"></el-input>
              </el-col>
              <el-col :span="3" class="text_right">
                <el-button type="primary" size="small" @click="billClick">收款</el-button>
              </el-col>
            </el-row>
          </el-footer>
        </el-container>
      </el-col>
    </el-row>

    <!--经手人-->
    <el-dialog title="经手人" :visible.sync="dialogVisible" width="800px">
      <div slot="title">
        <el-radio-group size="small" v-model="tabHandlePosition" @change='hangeHandleType'>
          <el-radio-button label="saleHandler">销售经手人</el-radio-button>
          <el-radio-button label="consumeHandler">消耗经手人</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-row class="padbm_10">
          <el-col :span="8">
            <el-input placeholder="请输入员工编号、姓名" prefix-icon="el-icon-search" v-model="handlerName" size="small" clearable></el-input>
          </el-col>
        </el-row>
        <el-tabs v-model="tabHandle">
          <el-tab-pane :label="handler.Name" :name="`${index}`" v-for="(handler,index) in getHandlerList()" :key="index">
            <el-row style="max-height: 300px;overflow-y: auto">
              <el-col :span="12" v-for="item in handler.Employee.filter(item => !handlerName || item.EmployeeName.toLowerCase().includes(handlerName.toLowerCase()) || item.EmployeeID.toLowerCase().includes(handlerName.toLowerCase())) " :key="item.EmployeeID" class="marbm_10 dis_flex flex_y_center">
                <el-checkbox v-model="item.Checked" @change="handlerCheckedChange(handler.Employee,item)">
                  <span class="marrt_10">{{item.EmployeeName}} [{{item.EmployeeID}}]</span>
                </el-checkbox>
                <el-input placeholder v-model="item.Discount" style="width: 120px" type="number" size="mini" min="0" max="100" v-enter-number @input="handlerPercentChange(handler.Employee,item)">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitHandleClick">确 定</el-button>
      </div>
    </el-dialog>

    <!--结账-->
    <el-dialog title="收银台" :visible.sync="dialogBill" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="900px">
      <div>
        <el-row>
          <el-col :span="8">
            <el-scrollbar class="el-scrollbar_height" style="height:500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height:32px">{{entityName}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">订单信息</span>
                </el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{getBillDate()}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{customerFullName}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{customerPhoneNumber}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{userName}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">消费明细</span>
                </el-divider>
                <template v-for="(item,index) in selectProject">
                  <div :key="index+item.type+item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1}} {{item.Name}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">(赠)</span>
                      </span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.number}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.discountPrice!=0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.discountPrice>0">-￥{{item.discountPrice | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>+￥{{mathAbsData(item.discountPrice) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardDiscountPrice!=0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">-￥{{item.CardDiscountPrice | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in selectSavingCard">
                  <div :key="index+item.type+item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">{{index+1+selectProject.length}} {{item.Name}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.Amount/item.number).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.LargessPrice>0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">充值赠送</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.LargessPrice/item.number).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.number}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Amount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in selectTimeCard">
                  <div :key="index+item.type+item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+selectProject.length+selectSavingCard.length}} {{item.Name}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">(赠)</span>
                      </span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.number}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.discountPrice!=0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.discountPrice>0">-￥{{item.discountPrice | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>+￥{{mathAbsData(item.discountPrice) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardDiscountPrice!=0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">-￥{{item.CardDiscountPrice | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in selectGeneralCard">
                  <div :key="index+item.type+item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+selectProject.length+selectSavingCard.length+selectTimeCard.length}} {{item.Name}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">(赠)</span>
                      </span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.number}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.discountPrice!=0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.discountPrice>0">-￥{{item.discountPrice | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>+￥{{mathAbsData(item.discountPrice) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardDiscountPrice!=0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">-￥{{item.CardDiscountPrice | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in selectProduct">
                  <div :key="index+item.type+item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+selectProject.length+selectSavingCard.length+selectTimeCard.length+selectGeneralCard.length}} {{item.Name}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">(赠)</span>
                      </span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.number}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.discountPrice!=0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.discountPrice>0">-￥{{item.discountPrice | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>+￥{{mathAbsData(item.discountPrice) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardDiscountPrice!=0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">-￥{{item.CardDiscountPrice | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in selectPackageCard">
                  <div :key="index+item.type+item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+selectProject.length+selectSavingCard.length+selectTimeCard.length+selectGeneralCard.length}} {{item.Name}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">(赠)</span>
                      </span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.number}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.discountPrice!=0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.discountPrice>0">-￥{{item.discountPrice | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>+￥{{mathAbsData(item.discountPrice) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardDiscountPrice!=0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">-￥{{item.CardDiscountPrice | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{(parseFloat(Amount)+parseFloat(PricePreferentialAmount)+parseFloat(CardPreferentialAmount)).toFixed(2) | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="PricePreferentialAmount!=0">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="PricePreferentialAmount>0">-￥{{PricePreferentialAmount | NumFormat}}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{mathAbsData(PricePreferentialAmount) | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="CardPreferentialAmount>0">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{CardPreferentialAmount | NumFormat}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单金额</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{Amount | NumFormat}}</span>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
          <el-col :span="16">
            <el-row type="flex" align="middle" class="dialog_bill_detail">
              <el-col :span="24">
                <div class="marbm_10">
                  <span class="font_20">待收款：</span>
                  <span class="font_20">¥{{PayAmount | NumFormat}}</span>
                </div>
                <div>
                  <span>订单金额：¥{{Amount | NumFormat}}</span>
                  <span v-if="ArrearAmount>0" class="color_gray font_12 marlt_10">
                    欠款：
                    <span class="color_red">-¥{{ArrearAmount | NumFormat}}</span>
                  </span>
                  <span class="color_gray font_12 marlt_10" v-if="(parseFloat(CardDeductionAmount)+parseFloat(cardDeductionAmount)).toFixed(2)>0">
                    <span>
                      卡抵扣：
                      <span class="color_red">-¥{{(parseFloat(CardDeductionAmount)+parseFloat(cardDeductionAmount)).toFixed(2) | NumFormat}}</span>
                    </span>
                  </span>
                  <span v-if="PayCashAmount>0" class="color_gray font_12 marlt_10">
                    付款：
                    <span class="color_red">-¥{{PayCashAmount | NumFormat}}</span>
                  </span>
                </div>
              </el-col>
            </el-row>
            <el-scrollbar class="el-scrollbar_height" style="height:415px">
              <div class="tip" style="margin-top:10px;margin-bottom:0px" v-if="savingCardAllGoods.length>0&savingCardPrice>0">
                <i class="el-icon-warning-outline"></i>
                储值卡支付金额为￥{{savingCardPrice|NumFormat}}，且不能卡抵扣支付。
              </div>
              <el-table :data="savingCardAllGoods" class="saving_discount martp_10" v-if="savingCardAllGoods.length>0" :show-header="false" size="small">
                <el-table-column type="selection" width="55">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.checked" @change="savingCheckedAllChange(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column label="商品信息">
                  <template slot-scope="scope">
                    <div>{{scope.row.SavingCardName}}</div>
                    <div>可用金额：¥ {{scope.row.TotalPrice | NumFormat}}</div>
                  </template>
                </el-table-column>
                <el-table-column label="卡扣金额">
                  <template slot-scope="scope">
                    <el-input size="small" type="number" v-model="scope.row.TotalAmount" v-enter-number2 placeholder="请输入抵扣金额" @input="savingPriceAllChange(scope.row)" :disabled="!scope.row.checked"></el-input>
                  </template>
                </el-table-column>
              </el-table>
              <el-table :data="payList" size="small" class="padtp_15" :show-header="false">
                <el-table-column prop="payName" label="选择收款方式">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.PayMethodID" placeholder="选择收款方式" size="small" clearable filterable @change="payMethodChange(scope.row)">
                      <el-option v-for="item in payTypeList" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="支付金额">
                  <template slot-scope="scope">
                    <el-input clearable v-model="scope.row.Amount" size="small" type="number" v-enter-number2 placeholder="请输入收款金额" :disabled="scope.row.PayMethodID ==''" @input="payPriceChange(scope.row)"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button type="danger" icon="el-icon-close" circle size="mini" @click="removePayClick(scope.$index)" v-if="(scope.$index+1) != 1"></el-button>
                    <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="addPayclick" v-if="(scope.$index+1) == payList.length"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogBill = false;" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="submitPayClick" :loading="modalLoading" v-prevent-click size="small">确定收款</el-button>
      </div>
    </el-dialog>

    <!--储值卡抵扣(部分商品)-->
    <el-dialog title="商品价格调整/储值卡抵扣" :visible.sync="dialogDeduction" width="700px">
      <el-row class="border pad_10 marbm_10 radius5" style="height:65px">
        <el-col :span="12" class="line_height_23">
          {{selectGood.Name}}
          <span v-if="selectGood.Alias">({{selectGood.Alias}})</span>
        </el-col>
        <el-col :span="6" class="color_gray font_13 line_height_23">¥ {{selectGood.Price | NumFormat}} × {{selectGood.number}}</el-col>
        <el-col :span="6" class="line_height_23">
          <div>¥ {{(selectGood.Price * selectGood.number -selectGood.DeductionProjectAmount).toFixed(2) | NumFormat}}</div>
          <div class="color_gray font_12 line_height_23" v-show="selectGood.DeductionProjectAmount!=0">
            合计优惠：
            <span class="color_red" v-if="selectGood.DeductionProjectAmount>0">-{{selectGood.DeductionProjectAmount | NumFormat}}</span>
            <span class="color_green" v-else>+{{mathAbsData(selectGood.DeductionProjectAmount) | NumFormat}}</span>
          </div>
        </el-col>
      </el-row>
      <template v-if="showModifyPrices">
        <el-row class="border pad_10 marbm_10 radius5" type="flex" align="middle" v-if="selectGood.isModify">
          <el-col :span="18">
            <span>手动改价:</span>
            <span class="mar_0_15">¥ {{selectGood.Amount | NumFormat}}</span>
            <el-button type="text" @click="selectGood.isModify = false" size="mini">改价</el-button>
          </el-col>
          <el-col :span="6" class="color_gray font_12" v-if="selectGood.discountPrice!=0">
            <span>手动改价：</span>
            <span class="color_red" v-if="selectGood.discountPrice>0">-{{selectGood.discountPrice | NumFormat}}</span>
            <span class="color_green" v-else>+{{mathAbsData(selectGood.discountPrice) | NumFormat}}</span>
          </el-col>
        </el-row>
        <el-row class="border pad_10 marbm_10 radius5" type="flex" align="middle" v-else>
          <el-col :span="4">
            <span>手动改价</span>
          </el-col>
          <el-col :span="14">
            <el-row type="flex" align="middle">
              <el-col :span="10">
                <el-input size="mini" v-model="selectGood.Amount" type="number" v-enter-number2 @input="amountChange(selectGood)">
                  <template slot="prepend">¥</template>
                </el-input>
              </el-col>
              <el-col :span="2" class="text_center">
                <i class="el-icon-sort"></i>
              </el-col>
              <el-col :span="10">
                <el-input size="mini" v-model="selectGood.discount" type="number" v-enter-number2 @input="discountChange(selectGood)">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" class="text_right">
            <el-button type="primary" size="mini" @click="modifyChange(selectGood)">确认</el-button>
          </el-col>
        </el-row>
      </template>

      <el-table class="saving_discount radius5" :data="savingCardAll" size="small" v-if="savingCardAll.length>0" :show-header="false" max-height="270px">
        <el-table-column type="selection" width="55">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked" @change="savingCardCheckedChange(selectGood,scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="储值卡">
          <template slot-scope="scope">
            <div class="font_14">
              {{scope.row.SavingCardName}}
              <span class="color_gray font_12" v-if="scope.row.PriceType == 1">{{scope.row.DiscountPrice}}折</span>
              <span class="color_gray font_12" v-else>¥ {{scope.row.DiscountPrice | NumFormat}}</span>
            </div>
            <div class="font_12">可用金额：¥ {{scope.row.TotalPrice | NumFormat}}</div>
          </template>
        </el-table-column>
        <el-table-column label="卡扣金额">
          <template slot-scope="scope">
            <el-input :disabled="!scope.row.checked" size="small" type="number" v-model="scope.row.TotalAmount" v-enter-number2 placeholder="请输入抵扣金额" @input="savingCardPriceChange(selectGood,scope.row)"></el-input>
            <div v-if="scope.row.cardDiscountPrice>0" class="color_red">卡优惠：-{{scope.row.cardDiscountPrice | NumFormat}}</div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-row type="flex" align="middle">
          <el-col :span="18" class="text_left font_14">
            <div>
              <span>
                <span>支付金额：</span>
                <span class="color_red">¥ {{selectGood.PayAmount | NumFormat}}</span>
              </span>
              <span class="font_12 color_gray" v-if="selectGood.CardDeductionAmount>0">
                （
                <span>卡抵扣：</span>
                <span>-¥ {{selectGood.CardDeductionAmount | NumFormat}}</span>）
              </span>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button @click="dialogDeduction = false" size="small">取消</el-button>
            <el-button type="primary" @click="submitSavingCard" size="small">确认</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <!--结账成功-->
    <el-dialog :visible.sync="dialogPay" width="450px" @close="closeSucceedDialog">
      <div class="text_center pad_15">
        <i class="el-icon-document" style="font-size: 80px ;color: #999"></i>
        <div class="pad_15 color_primary font_weight_600 font_18">订单已结账成功</div>
      </div>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">销售订单号：</el-col>
        <el-col :span="12" class="text_right">{{orderNumber}}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">消耗订单号：</el-col>
        <el-col :span="12" class="text_right">{{consumeOrderNumber}}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">订单金额：</el-col>
        <el-col :span="12" class="color_red text_right">¥{{orderAmount}}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="5">订单备注：</el-col>
        <el-col :span="19">{{Remark}}</el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogPay = false" size="small">继续开单</el-button>
        <el-button type="primary" @click="getOrderDetail" :loading="printSaleLoading" size="small">打印销售小票</el-button>
        <el-button type="primary" @click="showTreatInfo" :loading="printConsumeLoading" size="small">打印消耗小票</el-button>
      </div>
    </el-dialog>

    <!-- 打印小票 消耗订单 -->
    <el-dialog :visible.sync="treatCashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="treatInfo && entityName">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height:500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height:32px">{{entityName}}</span>
                </div>
                <el-divider><span class="font_12 color_gray">消耗信息</span></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{treatInfo.ID}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{treatInfo.BillDate}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.NameEncrypt?formatName(treatInfo.Name) : treatInfo.Name}}</span>

                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.MobileEncrypt?formatPhone(treatInfo.PhoneNumber):treatInfo.PhoneNumber}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{treatInfo.EmployeeName}}</span>
                </div>
                <div v-if="cashierReceipt.EntityAddress" class="dis_flex">
                  <span class="flex_box6 color_gray text-left line_height_24">地址：</span>
                  <span class="flex_box font_12 text_right line_height_24">{{treatInfo.AddressDetail}}</span>
                </div>
                <el-divider><span class="font_12 color_gray">消耗明细</span></el-divider>

                <template v-for="(item,index) in treatInfo.Project">
                  <div :key="index + 'Project' + item.ProjectID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1}} {{item.ProjectName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div v-if="cashierReceipt.TreatPromotions  && item.CardPreferentialAmount > 0" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.CardPreferentialAmount | NumFormat}}</span> -->
                      <span class="font_12 text_right line_height_24" v-if="item.CardPreferentialAmount<0">¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" v-if=" item.CardPreferentialAmount>0">¥ -{{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in treatInfo.SavingCard">
                  <div :key="index + 'SavingCard' + item.SavingCardID">
                    <div v-for="(Project,pIndex) in item.Project" :key="pIndex+item.SavingCardID+Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{
                          +treatInfo.Project.length
                          +getCurrentCardLength(treatInfo.SavingCard,index,pIndex)
                          }} {{Project.ProjectName}}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions  && Project.CardPreferentialAmount > 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                        <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.CardPreferentialAmount | NumFormat}}</span> -->

                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount<0">¥ {{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                        <span class="font_12 text_right line_height_24" v-if=" Project.CardPreferentialAmount>0">¥ -{{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.TreatPromotions  && Project.PricePreferentialAmount > 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.PricePreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                      </div>

                    </div>
                  </div>
                </template>

                <template v-for="(item,index) in treatInfo.TimeCard">
                  <div :key="index+'TimeCard'+item.TimeCardID">
                    <div v-for="(Project,pIndex) in item.Project" :key="pIndex+''+ item.TimeCardID+''+Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{
                          treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCurrentCardLength(treatInfo.TimeCard,index,pIndex)
                          }} {{Project.ProjectName}}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions   && Project.CardPreferentialAmount != 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount<0">¥ {{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                        <span class="font_12 text_right line_height_24" v-if=" Project.CardPreferentialAmount>0">¥ -{{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item,index) in treatInfo.GeneralCard">
                  <div :key="index+'GeneralCard'+item.GeneralCardID">
                    <div v-for="(Project,pIndex) in item.Project" :key="pIndex+item.SavingCardID+Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{
                          treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCardTotalLength(treatInfo.TimeCard)
                          +getCurrentCardLength(treatInfo.GeneralCard,index,pIndex)
                          }} {{Project.ProjectName}}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions   && item.CardPreferentialAmount > 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                        <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.CardPreferentialAmount | NumFormat}}</span> -->

                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount<0">¥ {{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                        <span class="font_12 text_right line_height_24" v-if=" Project.CardPreferentialAmount>0">¥ -{{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item,index) in treatInfo.Product">
                  <div :key="index+'Product'+item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1
                          +treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCardTotalLength(treatInfo.TimeCard)
                          +getCardTotalLength(treatInfo.GeneralCard)
                        }} {{item.ProductName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div v-if="cashierReceipt.TreatPromotions && item.CardPreferentialAmount > 0" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.CardPreferentialAmount | NumFormat}}</span> -->

                      <span class="font_12 text_right line_height_24" v-if="item.CardPreferentialAmount<0">¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" v-if=" item.CardPreferentialAmount>0">¥ -{{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in treatInfo.PackageCard">
                  <div :key="index+'PackageCard'+item.PackageCardID">
                    <!-- 套餐卡项目 -->
                    <div v-for="(packItem,Pindex) in item.Project" :key="Pindex + 'PProjectID' + packItem.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCardTotalLength(treatInfo.TimeCard)
                          +getCardTotalLength(treatInfo.GeneralCard)
                          +treatInfo.Product.length
                          }} {{packItem.ProjectName}}
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions  && packItem.CardPreferentialAmount > 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.CardPreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                    <!-- 套餐卡储值卡 -->
                    <div v-for="(packItem,Pindex) in item.SavingCard" :key="Pindex + 'PProjectID' + packItem.SavingCardID">
                      <div v-for="(Project,projectIndex) in packItem.Project" :key="projectIndex + item.PackageCardAccountID+packItem.SavingCardID+Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                            {{
                            +treatInfo.Project.length
                            +getCardTotalLength(treatInfo.SavingCard)
                            +getCardTotalLength(treatInfo.TimeCard)
                            +getCardTotalLength(treatInfo.GeneralCard)
                            +treatInfo.Product.length
                            +item.Project.length
                            +getCurrentCardLength(item.SavingCard,Pindex,projectIndex)
                            }} {{Project.ProjectName}}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                        </div>

                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                        </div>
                        <div v-if="cashierReceipt.TreatPromotions  && Project.CardPreferentialAmount > 0" class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                          <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.CardPreferentialAmount | NumFormat}}</span> -->
                          <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount<0">¥ {{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                          <span class="font_12 text_right line_height_24" v-if=" Project.CardPreferentialAmount>0">¥ -{{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                        </div>

                        <div v-if="cashierReceipt.TreatPromotions  && Project.PricePreferentialAmount > 0" class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                          <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.PricePreferentialAmount | NumFormat}}</span> -->
                          <span class="font_12 text_right line_height_24" v-if="Project.PricePreferentialAmount<0">¥ {{Math.abs(Project.PricePreferentialAmount) | NumFormat}}</span>
                          <span class="font_12 text_right line_height_24" v-if=" Project.PricePreferentialAmount>0">¥ -{{Math.abs(Project.PricePreferentialAmount) | NumFormat}}</span>
                        </div>

                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                        </div>

                      </div>
                    </div>
                    <!-- 套餐卡中 时效卡 -->
                    <div v-for="(packItem,Pindex) in item.TimeCard" :key="Pindex + 'PProjectID' + packItem.TimeCardID">
                      <div v-for="(Project,ProjectIndex) in packItem.Project" :key="ProjectIndex+item.PackageCardID+packItem.TimeCardID+Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                            {{
                            +treatInfo.Project.length
                            +getCardTotalLength(treatInfo.SavingCard)
                            +getCardTotalLength(treatInfo.TimeCard)
                            +getCardTotalLength(treatInfo.GeneralCard)
                            +treatInfo.Product.length
                            +item.Project.length
                            +getCardTotalLength(item.SavingCard)
                            +getCurrentCardLength(item.TimeCard,Pindex,projectIndex)
                            }} {{Project.ProjectName}}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                        </div>

                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                        </div>
                        <div v-if="cashierReceipt.TreatPromotions   && Project.CardPreferentialAmount > 0" class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.CardPreferentialAmount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                        </div>
                      </div>
                    </div>

                    <!-- 套餐卡中 通用次卡 -->
                    <div v-for="(packItem,Pindex) in item.GeneralCard" :key="Pindex + 'PProjectID' + packItem.GeneralCardID">
                      <div v-for="(Project,pIndex) in packItem.Project" :key="pIndex+item.PackageCardID+packItem.GeneralCardID+Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                            {{
                              
                            +treatInfo.Project.length
                            +getCardTotalLength(treatInfo.SavingCard)
                            +getCardTotalLength(treatInfo.TimeCard)
                            +getCardTotalLength(treatInfo.GeneralCard)
                            +treatInfo.Product.length
                            +item.Project.length
                            +getCardTotalLength(item.SavingCard)
                            +getCardTotalLength(item.TimeCard)
                            +getCurrentCardLength(item.GeneralCard,Pindex,projectIndex)
                            }} {{Project.ProjectName}}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                        </div>

                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                        </div>
                        <div v-if="cashierReceipt.TreatPromotions   && Project.CardPreferentialAmount > 0" class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.CardPreferentialAmount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                        </div>
                      </div>
                    </div>

                    <!-- 套餐卡产品 -->
                    <div v-for="(packItem,Pindex) in item.Product" :key="Pindex + 'PProductID' + packItem.ProductID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{+treatInfo.Project.length
                            +getCardTotalLength(treatInfo.SavingCard)
                            +getCardTotalLength(treatInfo.TimeCard)
                            +getCardTotalLength(treatInfo.GeneralCard)
                            +treatInfo.Product.length
                            +item.Project.length
                            +getCardTotalLength(item.SavingCard)
                            +getCardTotalLength(item.TimeCard)
                            +getCardTotalLength(item.GeneralCard)
                            +Pindex+1
                          }} {{packItem.ProductName}}
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions  && packItem.CardPreferentialAmount > 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.CardPreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{treatInfo.Amount | NumFormat}}</span>
                </div>
                <div v-if="treatInfo.SaleBillPay && treatInfo.SaleBillPay.length > 0 " class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
                  <div class="flex_box">
                    <div class="dis_flex" v-for="pay in treatInfo.SaleBillPay" :key="pay.ID + 'pay'">
                      <span class="flex_box color_gray line_height_24">{{pay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{pay.Amount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div v-if="treatInfo.SaleBillPaySavingCardDeduction && treatInfo.SaleBillPaySavingCardDeduction.length > 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
                  <div class="flex_box">
                    <div class="dis_flex flex_box" v-for="cardPay in treatInfo.SaleBillPaySavingCardDeduction" :key="cardPay.ID + 'cardPay'">
                      <span class="flex_box color_gray line_height_24">{{cardPay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{cardPay.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <div class="dis_flex" v-if="treatInfo.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="treatInfo.PricePreferentialAmount>0">-￥{{treatInfo.PricePreferentialAmount | NumFormat}}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{mathAbsData(treatInfo.PricePreferentialAmount) | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="treatInfo.CardPreferentialAmount>0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{treatInfo.CardPreferentialAmount | NumFormat}}</span>
                </div>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex flex_dir_column font_14 font_weight_600 flex_y_center color_999 padbm_10">
                  <span>{{cashierReceipt.WriteTextFirst}}</span>
                  <span>{{cashierReceipt.WriteTextSecond}}</span>
                </div>

                <div class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">签字：</span>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="treatCashierReceiptDialogVisible = false;" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="printTreatBillContent" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>

    <!-- 打印 销售 小票 -->
    <el-dialog :visible.sync="cashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="saleOrderDetail && entityName">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height:500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height:32px">{{entityName}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">订单信息</span>
                </el-divider>

                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{saleOrderDetail.ID}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{saleOrderDetail.BillDate}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.NameEncrypt?formatName(saleOrderDetail.Name) : saleOrderDetail.Name}}</span>

                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.MobileEncrypt?formatPhone(saleOrderDetail.PhoneNumber):saleOrderDetail.PhoneNumber}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{saleOrderDetail.EmployeeName}}</span>
                </div>
                <div v-if="cashierReceipt.EntityAddress" class="dis_flex">
                  <span class="flex_box6 color_gray text-left line_height_24">地址：</span>
                  <span class="flex_box font_12 text_right line_height_24">{{saleOrderDetail.AddressDetail}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">消费明细</span>
                </el-divider>
                <template v-for="(item,index) in saleOrderDetail.Project">
                  <div :key="index + 'Project' + item.ProjectID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1}} {{item.ProjectName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.PricePreferentialAmount!=0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.PricePreferentialAmount>0">-￥{{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>+￥{{mathAbsData(item.PricePreferentialAmount) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardPreferentialAmount!=0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">-￥{{item.CardPreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.SavingCard">
                  <div :key="index + 'SavingCard' + item.SavingCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">{{index+1+saleOrderDetail.Project.length}} {{item.SavingCardName}}</span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.Amount/item.Quantity).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.LargessPrice>0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">充值赠送</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.LargessPrice/item.Quantity).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleGoodsOriginPrice" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Amount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.TimeCard">
                  <div :key="index+'TimeCard'+item.TimeCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length}} {{item.TimeCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.PricePreferentialAmount>0">-￥{{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>+￥{{mathAbsData(item.PricePreferentialAmount) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardPreferentialAmount!=0  && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">-￥{{item.CardPreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.GeneralCard">
                  <div :key="index+'GeneralCard'+item.GeneralCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length}} {{item.GeneralCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.PricePreferentialAmount>0">-￥{{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>+￥{{mathAbsData(item.PricePreferentialAmount) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardPreferentialAmount!=0  && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">-￥{{item.CardPreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.Product">
                  <div :key="index+'Product'+item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length+saleOrderDetail.GeneralCard.length}} {{item.ProductName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.PricePreferentialAmount>0">-￥{{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>+￥{{mathAbsData(item.PricePreferentialAmount) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardPreferentialAmount!=0  && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">-￥{{item.CardPreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.PackageCard">
                  <div :key="index+'PackageCard'+item.PackageCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length+saleOrderDetail.GeneralCard.length + saleOrderDetail.Product.length}} {{item.PackageCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.PricePreferentialAmount>0">-￥{{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>+￥{{mathAbsData(item.PricePreferentialAmount) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardPreferentialAmount!=0  && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">-￥{{item.CardPreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{saleOrderDetail.TotalAmount | NumFormat}}</span>
                </div>
                <div v-if="saleOrderDetail.SaleBillPay && saleOrderDetail.SaleBillPay.length > 0 " class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
                  <div class="flex_box">
                    <div class="dis_flex flex_box" v-for="pay in saleOrderDetail.SaleBillPay" :key="pay.ID + 'pay'">
                      <span class="flex_box color_gray line_height_24">{{pay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{pay.Amount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div v-if="saleOrderDetail.SaleBillPaySavingCardDeduction && saleOrderDetail.SaleBillPaySavingCardDeduction.length > 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
                  <div class="flex_box">
                    <div class="dis_flex flex_box" v-for="cardPay in saleOrderDetail.SaleBillPaySavingCardDeduction" :key="cardPay.ID + 'cardPay'">
                      <span class="flex_box color_gray line_height_24">{{cardPay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{cardPay.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="saleOrderDetail.PricePreferentialAmount>0">-￥{{saleOrderDetail.PricePreferentialAmount | NumFormat}}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{mathAbsData(saleOrderDetail.PricePreferentialAmount) | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.CardPreferentialAmount>0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{saleOrderDetail.CardPreferentialAmount | NumFormat}}</span>
                </div>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex flex_dir_column font_14 font_weight_600 flex_y_center color_999 padbm_10">
                  <span>{{cashierReceipt.WriteTextFirst}}</span>
                  <span>{{cashierReceipt.WriteTextSecond}}</span>
                </div>

                <div class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">签字：</span>
                </div>

              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cashierReceiptDialogVisible = false;" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="getprintSaleBillContent" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import API from "@/api/iBeauty/Order/saleGoods";
import consumeAPI from "@/api/iBeauty/Order/consumeGoods";
import agileSellAPI from "@/api/iBeauty/Order/agileSell";
import date from "@/components/js/date";

import orderAPI from "@/api/iBeauty/Order/treatBill";
import orderSellAPI from "@/api/iBeauty/Order/saleBill";
import printReceipt from "@/components/js/print";
import cashierAPI from "@/api/iBeauty/Order/cashierReceipt";

var Enumerable = require("linq");
var socket;

export default {
  props: {
    billDate: String,
    isSupplement: Boolean,
    customerID: Number,
    customerFullName: String,
    customerPhoneNumber: String,
    SellPermission: Object,
  },
  data() {
    return {
      printSaleLoading: false,
      printConsumeLoading: false,
      cashierReceiptDialogVisible: false,
      treatCashierReceiptDialogVisible: false,
      showModifyPrices: false,
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      dialogBill: false,
      dialogDeduction: false,
      dialogDeductionPackage: false,
      dialogPay: false,
      goodsName: "",
      typeIndex: "0",
      goodsType: "Project",
      projectCategoryIndex: 0,
      productCategoryIndex: 0,
      categorySubIndex: "0",
      tabPane: "1",
      orderNumber: "",
      orderAmount: 0,
      BillID: "",
      Amount: 0, //订单金额
      PayAmount: 0, //待支付金额（待收款）
      PayCashAmount: 0, //现金支付金额
      payTotalPrice: 0,
      ArrearAmount: 0, //欠款金额
      cardDeductionAmount: 0,
      CardDeductionAmount: 0, // 储值卡抵扣金额
      PricePreferentialAmount: 0, //手动改价优惠金额
      CardPreferentialAmount: 0, //卡优惠金额
      savingCardPrice: 0, //储值卡金额
      Remark: "", //备注
      type: 1,
      handlerName: "",
      collapseName: [],
      goodsAll: [],
      goodsList: [], //全部商品
      projectList: [], //项目
      productList: [], //产品
      productCollapseName: [],
      projectCategory: [], //项目
      productCategory: [], //产品
      selectProject: [],
      selectProduct: [],
      selectGeneralCard: [],
      selectTimeCard: [],
      selectSavingCard: [],
      selectPackageCard: [],
      selectGoods: "",
      selectGood: "",
      goodsIndex: "",

      handlerList: [], //公共经手人列表
      productHandlerList: [], //产品经手人列表
      productTreatHandlers: [], //产品消耗经手人
      projectHandlerList: [], //项目经手人列表
      projectTreatHandlers: [], //项目消耗经手人

      tabHandle: "0",
      savingCardAllGoods: [], // 通用储值卡
      savingCardSomeGoods: [], // 非通用储值卡
      savingCardAll: [],
      payTypeList: [],
      payList: [{ PayMethodID: "", Amount: "", price: 0 }],
      SavingCardDeduction: [],
      name: "",
      IsExistProduct: false,
      IsExistProject: false,
      userName: "",
      entityName: "",
      tabHandlePosition: "saleHandler",
      cashierReceipt: "",
      treatInfo: "",
      saleOrderDetail: "",
      consumeOrderNumber: "",
    };
  },
  methods: {
    mathAbsData: function (item) {
      return Math.abs(item);
    },
    // 修改会员
    changMemberOrType: function () {
      var that = this;
      if (that.customerID != null) {
        that.savingCardAllGoodsData();
        that.savingCardSomeGoodsData();
        that.getReceiptConfig();
      }
    },
    /**  清除卡抵扣信息  */
    clearAgileSellData() {
      var that = this;
      that.savingCardAllGoods = [];
      that.savingCardSomeGoods = [];
    },
    //获取开单时间
    getBillDate: function () {
      var that = this;
      return that.isSupplement
        ? that.billDate
        : date.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss");
    },
    // 全部商品
    goodsData: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.goodsName,
        BillDate: that.getBillDate(),
      };
      agileSellAPI
        .getAgileSaleGoods(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.goodsAll = [];
            that.goodsList = res.Data;
            if (res.Data.Project.length > 0) {
              that.goodsAll = res.Data.Project;
              that.goodsType = "Project";
            } else if (res.Data.Product.length > 0) {
              that.goodsAll = res.Data.Product;
              that.goodsType = "Product";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 全部商品
    goodsDataInit: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        BillDate: that.getBillDate(),
      };
      agileSellAPI
        .getAgileSaleGoods(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.goodsAll = [];
            that.goodsList = res.Data;
            if (res.Data.Project.length > 0) {
              that.goodsAll = res.Data.Project;
              that.goodsType = "Project";
              that.IsExistProject = true;
            } else if (res.Data.Product.length > 0) {
              that.goodsAll = res.Data.Product;
              that.goodsType = "Product";
              that.IsExistProduct = true;
            }
            //判断是否有对应的商品
            if (res.Data.Project.length > 0) {
              that.IsExistProject = true;
            } else {
              that.IsExistProject = false;
            }
            if (res.Data.Product.length > 0) {
              that.IsExistProduct = true;
            } else {
              that.IsExistProduct = false;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 项目
    projectData: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.goodsName,
      };
      API.getSaleGoodsProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectList = [];
            that.projectCategory = res.Data;
            if (that.projectCategory[0] != undefined) {
              that.projectList = that.projectCategory[0].Child;
              that.projectList.forEach(function (item) {
                that.collapseName.push(item.ID);
              });
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 产品
    productData: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.goodsName,
      };
      API.getSaleGoodsProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productList = [];
            that.productCategory = res.Data;
            if (that.productCategory[0] != undefined) {
              that.productList = that.productCategory[0].Child;
              that.productList.forEach(function (item) {
                that.productCollapseName.push(item.ID);
              });
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 销售项目经手人
    projectHandlerData: function () {
      var that = this;
      that.loading = true;
      API.getProjectHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 销售产品经手人
    productHandlerData: function () {
      var that = this;
      that.loading = true;
      API.getProductHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.productHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  52.11.获取项目消耗经手人 */
    treatProjectHandlerNet: function () {
      var that = this;
      that.loading = true;
      var params = {};
      consumeAPI
        .treatGoodsProjectHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectTreatHandlers = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  52.12.获取产品消耗经手人 */
    treatProductHandlerNet: function () {
      var that = this;
      that.loading = true;
      var params = {};
      consumeAPI
        .treatGoodsProductHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productTreatHandlers = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 支付方式
    salePayMethodData: function () {
      var that = this;
      that.loading = true;
      API.getSalePayMethod()
        .then((res) => {
          if (res.StateCode == 200) {
            that.payTypeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 会员通用储值卡
    savingCardAllGoodsData: function () {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
      };
      API.getSavingCardAllGoods(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardAllGoods = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 会员非通用储值卡（指定商品抵扣卡）
    savingCardSomeGoodsData: function () {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
      };
      API.getSavingCardSomeGoods(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardSomeGoods = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 可抵扣产品的储值卡列表
    savingCardProductData: function (item) {
      var that = this;
      if (that.customerID == "" || that.customerID == null) {
        return false;
      }
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        ProductID: item.ID,
      };
      API.getSavingCardProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardDeductionData(res.Data, item);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 可抵扣项目的储值卡列表
    savingCardProjecctData: function (item) {
      var that = this;
      that.loading = true;
      if (that.customerID == "" || that.customerID == null) {
        return false;
      }
      var params = {
        CustomerID: that.customerID,
        ProjectID: item.ID,
      };
      API.getSavingCardProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardDeductionData(res.Data, item);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    savingCardDeductionData: function (data, item) {
      var that = this;
      var savingCardAll = [];
      data.forEach(function (discount) {
        if (discount.PriceType == 1) {
          discount.DiscountPrice = discount.DiscountPrice * 10;
        }
      });

      that.savingCardSomeGoods.forEach(function (some) {
        data.forEach(function (project) {
          if (some.ID == project.ID) {
            savingCardAll.push({
              checked: false,
              AccountID: some.AccountID,
              Balance: some.Balance,
              ID: some.ID,
              LargessBalance: some.LargessBalance,
              SavingCardName: some.SavingCardName,
              TotalBalance: some.TotalBalance,
              TotalPrice: some.TotalBalance,
              Type: some.Type,
              DiscountPrice: project.DiscountPrice,
              PriceType: project.PriceType,
              TotalAmount: "",
              PreferentialAmount: 0,
              cardDiscountPrice: 0,
              cardDeductionAmount: 0,
            });
          }
        });
      });
      savingCardAll.forEach(function (saving) {
        item.savingCardDeduction.forEach(function (deduction) {
          if (saving.ID == deduction.ID) {
            saving.checked = true;
            saving.TotalAmount = deduction.TotalAmount || 0;
            saving.TotalBalance = (
              parseFloat(saving.TotalBalance) +
              parseFloat(deduction.TotalAmount)
            ).toFixed(2);
            saving.TotalPrice = (
              saving.TotalBalance - saving.TotalAmount
            ).toFixed(2);
            saving.PreferentialAmount = deduction.PreferentialAmount;
            saving.cardDiscountPrice = deduction.cardDiscountPrice;
            saving.cardDeductionAmount = deduction.cardDeductionAmount;
          }
        });
      });
      that.savingCardAll = Enumerable.from(savingCardAll)
        .where((i) => {
          return i.TotalBalance > 0;
        })
        .toArray();
    },
    // 搜索商品
    searchGoodsClick: function () {
      var that = this;
      that.tabPane = that.typeIndex;
      switch (that.typeIndex) {
        case "0":
          that.goodsData();
          break;
        case "1":
          that.projectData();
          break;
        case "2":
          that.productData();
          break;
        default:
          that.goodsData();
      }
    },
    // 清空
    clearClick: function () {
      var that = this;
      // that.searchGoodsClick();
      that.goodsData();
      that.projectData();
      that.productData();
    },

    // 全部卡项切换
    goodsCategoryChange: function (item) {
      var that = this;
      that.goodsAll = that.goodsList[item];
      that.goodsType = item;
    },

    /** =====项目 =================================================================   */
    // 项目一级分类
    projectCategoryChange: function (item, index) {
      var that = this;
      that.collapseName = [];
      that.projectCategoryIndex = index;
      that.projectList = item.Child;
      that.collapseName = Enumerable.from(that.projectList)
        .select((item) => item.ID)
        .toArray();
      // that.projectList.forEach(function (item) {
      //   that.collapseName.push(item.ID);
      // });
      // that.categorySubIndex = "0";
    },
    // 二级分类
    // categorySubChange: function (index) {
    //   var that = this;
    //   that.categorySubIndex = index;
    // },
    // 项目选择
    projectChange: function (row) {
      var that = this;
      var data = {
        type: 1,
        Alias: row.Alias,
        ID: row.ID,
        IsAllowLargess: row.IsAllowLargess,
        IsLargess: false,
        Name: row.Name,
        Price: row.Price,
        totalPrice: row.Price,
        number: 1,
        discount: 100,
        Amount: row.Price,
        PayAmount: row.Price,
        TotalAmount: row.Price,
        ArrearAmount: 0,
        isModify: true,
        DeductionProjectAmount: 0,
        discountPrice: 0,
        CardDeductionAmount: 0,
        CardDiscountPrice: 0,
        savingCardDeduction: [],
        saleHandlerList: [],
        consumeHandlerList: [],
      };

      data.saleHandlerList = Enumerable.from(that.projectHandlerList)
        .select((item) => ({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        }))
        .toArray();

      data.consumeHandlerList = Enumerable.from(that.projectTreatHandlers)
        .select((item) => ({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        }))
        .toArray();

      that.selectProject.push(data);

      that.payAmountData();
    },

    /** =====产品 =================================================================   */
    // 产品一级分类
    productCategoryChange: function (item, index) {
      // var that = this;
      // that.productList = item.Product;
      // that.productCategoryIndex = index;

      var that = this;
      that.productCollapseName = [];
      that.productCategoryIndex = index;
      that.productList = item.Child;
      that.productCollapseName = Enumerable.from(that.productList)
        .select((item) => item.ID)
        .toArray();
      // that.projectList.forEach(function (item) {
      //   that.collapseName.push(item.ID);
      // });
      // that.categorySubIndex = "0";
    },
    // 二级分类
    // productCategorySubChange: function (index) {
    //   var that = this;
    //   that.categorySubIndex = index;
    // },
    // 产品选择
    productChange: function (row) {
      var that = this;
      var data = {
        type: 6,
        Alias: row.Alias,
        ID: row.ID,
        IsAllowLargess: row.IsAllowLargess,
        IsLargess: false,
        Name: row.Name,
        Price: row.Price,
        totalPrice: row.Price,
        number: 1,
        discount: 100,
        Amount: row.Price,
        PayAmount: row.Price,
        TotalAmount: row.Price,
        ArrearAmount: 0,
        isModify: true,
        DeductionProjectAmount: 0,
        discountPrice: 0,
        CardDeductionAmount: 0,
        CardDiscountPrice: 0,
        savingCardDeduction: [],
        saleHandlerList: [],
        consumeHandlerList: [],
      };
      data.saleHandlerList = Enumerable.from(that.productHandlerList)
        .select((item) => ({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        }))
        .toArray();

      data.consumeHandlerList = Enumerable.from(that.productTreatHandlers)
        .select((item) => ({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        }))
        .toArray();

      that.selectProduct.push(data);
      that.payAmountData();
    },

    // 锚点
    navChange: function (index, selector) {
      var that = this;
      var anchor = this.$el.querySelector(selector);
      that.$el.querySelector(".el_scrollbar_project").scrollTop =
        anchor.offsetTop;
    },
    // 商品选择
    goodsChange: function (row) {
      var that = this;
      switch (that.goodsType) {
        case "Project":
          that.projectChange(row);
          break;
        case "Product":
          that.productChange(row);
          break;
      }
    },

    /**  修改经手人类型 销售、消耗  */
    hangeHandleType(label) {
      var that = this;
      that.tabHandle = "0";
    },

    /** 获取经手人   */
    getHandlerList() {
      var that = this;
      if (that.tabHandlePosition == "saleHandler") {
        return that.handlerList.saleHandlerList;
      } else {
        return that.handlerList.consumeHandlerList;
      }
    },

    // 经手人
    employeeHandleClick: function (type, row, item, index, isSale) {
      console.log("item", item);
      var that = this;
      var saleEmplayee = [];
      var consumeEmplayee = [];
      that.tabHandle = "0";
      that.handlerList = {};
      that.tabHandlePosition = isSale ? "saleHandler" : "consumeHandler";
      switch (type) {
        case 1:
          that.handlerList.saleHandlerList = that.projectHandlerList;
          that.handlerList.consumeHandlerList = that.projectTreatHandlers;
          break;
        case 2:
          that.handlerList.saleHandlerList = that.productHandlerList;
          that.handlerList.consumeHandlerList = that.productTreatHandlers;
          break;
      }

      /**  销售 ===================  */
      Enumerable.from(item.saleHandlerList).forEach(function (hand) {
        hand.Employee.forEach(function (emp) {
          saleEmplayee.push({ ID: emp.ID, Discount: emp.Discount });
        });
      });
      // 选中
      Enumerable.from(that.handlerList.saleHandlerList).forEach((handler) => {
        handler.Employee.forEach(function (emp) {
          emp.Checked = false;
          emp.Discount = "";
          Enumerable.from(saleEmplayee).forEach(function (i) {
            if (emp.ID == i.ID) {
              emp.Checked = true;
              emp.Discount = i.Discount;
            }
          });
        });
      });

      /**  消耗===================  */
      Enumerable.from(item.consumeHandlerList).forEach(function (hand) {
        hand.Employee.forEach(function (emp) {
          consumeEmplayee.push({ ID: emp.ID, Discount: emp.Discount });
        });
      });
      // 选中
      Enumerable.from(that.handlerList.consumeHandlerList).forEach(
        (handler) => {
          handler.Employee.forEach(function (emp) {
            emp.Checked = false;
            emp.Discount = "";
            Enumerable.from(consumeEmplayee).forEach(function (i) {
              if (emp.ID == i.ID) {
                emp.Checked = true;
                emp.Discount = i.Discount;
              }
            });
          });
        }
      );

      that.type = type;
      that.selectGoods = row;
      that.goodsIndex = index;
      that.dialogVisible = true;
    },
    // 经手人确认选择
    submitHandleClick: function () {
      var that = this;
      var goodsHandler = JSON.parse(JSON.stringify(that.handlerList));

      Enumerable.from(goodsHandler).forEach((saleType) => {
        Enumerable.from(saleType.value).forEach(function (item) {
          item.Employee = Enumerable.from(item.Employee)
            .where(function (i) {
              return i.Checked;
            })
            .toArray();
        });
      });
      that.selectGoods[that.goodsIndex].saleHandlerList =
        goodsHandler.saleHandlerList;
      that.selectGoods[that.goodsIndex].consumeHandlerList =
        goodsHandler.consumeHandlerList;

      switch (that.type) {
        case 1:
          that.selectProject = that.selectGoods;
          break;
        case 2:
          that.selectProduct = that.selectGoods;
          break;
      }
      that.dialogVisible = false;
    },
    // 删除经手人
    removeHandleClick: function (item, index) {
      item.Employee.splice(index, 1);
    },
    // 删除项目
    removeClick: function (type, index, item) {
      var that = this;
      switch (type) {
        case 1:
          that.selectProject.splice(index, 1);
          break;
        case 2:
          that.selectProduct.splice(index, 1);
          break;
      }
      if (item.savingCardDeduction != undefined) {
        that.deductionReset(item);
      }
      that.payAmountData();
    },
    // 经手人选择
    handlerCheckedChange: function (row, item) {
      var that = this;
      var discount = 0;
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
      if (!item.Checked) {
        item.Discount = "";
      } else {
        if (item.Discount == "") {
          if (discount > 100) {
            item.Discount = 0;
          } else {
            item.Discount = 100 - discount;
          }
        }
      }
    },
    // 百分比
    handlerPercentChange: function (row, item) {
      var that = this;
      var discount = 0;
      if (item.Discount != "") {
        item.Discount = parseFloat(item.Discount);
      }
      if (item.Discount > 100) {
        item.Discount = 100;
      }
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
    },

    // 储值卡抵扣(部分商品)
    savingCardDeductionClick: function (type, row, item, index) {
      item.PayAmount =
        parseFloat(item.PayAmount) + (parseFloat(item.ArrearAmount) || 0);
      item.ArrearAmount = 0;
      var that = this;
      that.savingCardAll = [];
      switch (type) {
        case 1:
          that.showModifyPrices = that.SellPermission.ModifyPrices_SaleProject;
          that.savingCardProjecctData(item);
          break;
        case 2:
          that.showModifyPrices = that.SellPermission.ModifyPrices_SaleProduct;
          that.savingCardProductData(item);
          break;
      }
      that.type = type;
      that.selectGoods = row;
      that.selectGood = Object.assign({}, item);
      that.goodsIndex = index;
      item.ArrearAmount = 0;
      that.dialogDeduction = true;
    },
    // 折扣
    discountChange: function (row) {
      var that = this;
      //清除选中的储值卡
      that.savingCardAll.forEach(function (item) {
        item.checked = false;
        item.TotalAmount = "";
        that.savingCardCheckedChange(row, item);
      });
      //计算金额
      row.Amount = ((row.Price * row.number * row.discount) / 100).toFixed(2);
      //计算优惠
      row.discountPrice = (row.totalPrice - row.Amount).toFixed(2);
      that.payPriceData(row);
    },
    // 折后金额
    amountChange: function (row) {
      var that = this;
      //清除选中的储值卡
      that.savingCardAll.forEach(function (item) {
        item.checked = false;
        item.TotalAmount = "";
        that.savingCardCheckedChange(row, item);
      });
      //计算折扣
      row.discount = parseInt((row.Amount / row.Price / row.number) * 100);
      //计算优惠
      row.discountPrice = (row.totalPrice - row.Amount).toFixed(2);
      that.payPriceData(row);
    },
    // 改价
    modifyChange: function (row) {
      var that = this;
      row.isModify = !row.isModify;
      if (row.Amount == "") {
        row.Amount = 0;
      }
      row.discountPrice = (row.totalPrice - row.Amount).toFixed(2);
      that.payPriceData(row);
    },
    // 储值卡抵扣选择
    savingCardCheckedChange: function (row, item) {
      var that = this;
      var amount;
      if (item.checked) {
        if (item.PriceType == 1) {
          var payAmount = (
            row.totalPrice -
            row.DeductionProjectAmount -
            row.CardDeductionAmount -
            row.ArrearAmount +
            parseFloat(item.cardDiscountPrice) +
            parseFloat(item.cardDeductionAmount)
          ).toFixed(2);
          amount = ((item.DiscountPrice / 10) * payAmount).toFixed(2);
          if (parseFloat(amount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
          } else {
            item.TotalAmount = amount;
          }
          item.PreferentialAmount = (
            item.TotalAmount /
            (item.DiscountPrice / 10)
          ).toFixed(2);
        } else {
          amount = ((row.PayAmount * item.DiscountPrice) / row.Price).toFixed(
            2
          );
          if (parseFloat(amount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
          } else {
            item.TotalAmount = (
              row.PayAmount /
              (row.Price / item.DiscountPrice)
            ).toFixed(2);
          }

          item.PreferentialAmount = (
            (row.Price / item.DiscountPrice) *
            item.TotalAmount
          ).toFixed(2);
        }
        item.cardDeductionAmount = item.TotalAmount;

        item.cardDiscountPrice = (
          item.PreferentialAmount - item.TotalAmount
        ).toFixed(2);
        item.TotalPrice = (item.TotalBalance - item.TotalAmount).toFixed(2);
      } else {
        item.TotalAmount = "";
        item.cardDeductionAmount = 0;
        item.cardDiscountPrice = 0;
        item.PreferentialAmount = 0;
        item.TotalPrice = item.TotalBalance;
      }
      that.savingCardDeductionPrice(row);
      if (
        parseFloat(amount) < parseFloat(item.TotalBalance) &&
        parseFloat(row.PayAmount) < 0.1
      ) {
        item.cardDiscountPrice = (
          parseFloat(item.cardDiscountPrice) + parseFloat(row.PayAmount)
        ).toFixed(2);
        row.PayAmount = 0;
        that.savingCardDeductionPrice(row);
      }
    },
    // 储值卡抵扣金额变化
    savingCardPriceChange: function (row, item) {
      var that = this;
      var amount;
      if (item.PriceType == 1) {
        let payAmount = (
          row.totalPrice -
          row.DeductionProjectAmount -
          row.CardDeductionAmount -
          row.ArrearAmount +
          parseFloat(item.cardDiscountPrice) +
          parseFloat(item.cardDeductionAmount)
        ).toFixed(2);
        amount = ((item.DiscountPrice / 10) * payAmount).toFixed(2);
        if (parseFloat(amount) > parseFloat(item.TotalBalance)) {
          if (parseFloat(item.TotalAmount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
            that.$message.error("卡扣金额不能大于" + item.TotalBalance + "元");
          }
        } else {
          if (parseFloat(item.TotalAmount) > parseFloat(amount)) {
            item.TotalAmount = amount;
            that.$message.error("卡扣金额不能大于" + amount + "元");
          }
        }
        item.PreferentialAmount = (
          item.TotalAmount /
          (item.DiscountPrice / 10)
        ).toFixed(2);
      } else {
        var payAmount = (
          row.totalPrice -
          row.DeductionProjectAmount -
          row.CardDeductionAmount -
          row.ArrearAmount +
          parseFloat(item.cardDiscountPrice) +
          parseFloat(item.cardDeductionAmount)
        ).toFixed(2);
        amount = ((payAmount * item.DiscountPrice) / row.Price).toFixed(2);
        if (parseFloat(amount) > parseFloat(item.TotalBalance)) {
          if (parseFloat(item.TotalAmount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
            that.$message.error("卡扣金额不能大于" + item.TotalBalance + "元");
          }
        } else {
          if (parseFloat(item.TotalAmount) > parseFloat(amount)) {
            item.TotalAmount = amount;
            that.$message.error("卡扣金额不能大于" + amount + "元");
          }
        }
        item.PreferentialAmount = (
          (row.Price / item.DiscountPrice) *
          item.TotalAmount
        ).toFixed(2);
      }
      item.cardDiscountPrice = (
        item.PreferentialAmount - item.TotalAmount
      ).toFixed(2);
      item.TotalPrice = (item.TotalBalance - item.TotalAmount).toFixed(2);
      item.cardDeductionAmount = item.TotalAmount;
      that.savingCardDeductionPrice(row);
      if (
        parseFloat(amount) < parseFloat(item.TotalBalance) &&
        parseFloat(row.PayAmount) < 0.1
      ) {
        item.cardDiscountPrice = (
          parseFloat(item.cardDiscountPrice) + parseFloat(row.PayAmount)
        ).toFixed(2);
        row.PayAmount = 0;
        that.savingCardDeductionPrice(row);
      }
    },
    // 套餐卡储值卡抵扣选择

    // 储值卡抵扣确认
    submitSavingCard: function () {
      var that = this;
      that.selectGoods[that.goodsIndex] = that.selectGood;
      var savingCardAll = that.savingCardAll;
      that.savingCardSomeGoods.forEach(function (item) {
        that.savingCardAll.forEach(function (sav) {
          if (item.ID == sav.ID) {
            item.TotalBalance = sav.TotalPrice;
          }
        });
      });
      savingCardAll = Enumerable.from(savingCardAll)
        .where(function (i) {
          return i.checked;
        })
        .toArray();
      that.selectGoods[that.goodsIndex].savingCardDeduction = savingCardAll;
      switch (that.type) {
        case 1:
          that.selectProject = that.selectGoods;
          break;
        case 2:
          that.selectProduct = that.selectGoods;
          break;
      }
      that.dialogDeduction = false;
      that.payAmountData();
    },

    // 储值卡抵扣总金额
    savingCardDeductionPrice: function (row) {
      var that = this;
      var CardDeductionAmount = 0;
      var cardDiscountPrice = 0;
      var savingCardAll = Enumerable.from(that.savingCardAll)
        .where(function (i) {
          return i.checked;
        })
        .toArray();
      savingCardAll.forEach(function (item) {
        var cardDeductionAmount = parseFloat(item.cardDeductionAmount) || 0;
        if (cardDeductionAmount == "") {
          cardDeductionAmount = 0;
        }
        CardDeductionAmount = (
          parseFloat(CardDeductionAmount) + cardDeductionAmount
        ).toFixed(2);
        cardDiscountPrice = (
          parseFloat(cardDiscountPrice) + parseFloat(item.cardDiscountPrice)
        ).toFixed(2);
      });
      row.CardDeductionAmount = CardDeductionAmount;
      row.CardDiscountPrice = cardDiscountPrice;
      that.payPriceData(row);
      if (row.type == 5) {
        that.packageSavingCardDeductionAmount(row);
      }
    },

    // 储值卡抵扣确认(套餐卡)
    /**  
    submitSavingCardPackage: function () {
      var that = this;
      that.selectGoods[that.goodsIndex] = that.selectGood;
      var savingCardAll = that.savingCardAll;
      that.savingCardSomeGoods.forEach(function (item) {
        that.savingCardAll.forEach(function (sav) {
          if (item.ID == sav.ID) {
            item.TotalBalance = sav.TotalPrice;
          }
        });
      });
      savingCardAll = Enumerable.from(savingCardAll)
        .where(function (i) {
          return i.checked;
        })
        .toArray();
      that.selectGoods[that.goodsIndex].savingCardDeduction = savingCardAll;

      that.selectPackageCard = that.selectGoods;

      that.dialogDeductionPackage = false;
      that.payAmountData();
    },
      */

    // 储值卡抵扣(通用)
    /**  
    savingCardDeductionAllClick: function() {
      var that = this;
      if (that.savingCardAllGoods.length == 0) {
        return false;
      }
      that.savingCardAllGoods = Enumerable.from(that.savingCardAllGoods)
        .select(val => ({
          checked: false,
          AccountID: val.AccountID,
          Balance: val.Balance,
          ID: val.ID,
          LargessBalance: val.LargessBalance,
          SavingCardName: val.SavingCardName,
          TotalBalance: val.TotalBalance,
          TotalPrice: val.TotalBalance,
          Type: val.Type,
          TotalAmount: ""
        }))
        .toArray();
      that.savingCardAllGoods.forEach(function(item) {
        that.SavingCardDeduction.forEach(function(card) {
          if (item.ID == card.ID) {
            item.checked = card.checked;
            item.TotalAmount = card.TotalAmount;
          }
        });
      });
    },
      */

    // 储值卡抵扣选择(通用)
    savingCheckedAllChange: function (item) {
      var that = this;
      // var savingCardPrice = 0;
      var PayAmount = 0;
      // that.selectSavingCard.forEach(function(item) {
      //   savingCardPrice = (
      //     parseFloat(savingCardPrice) + parseFloat(item.PayAmount)
      //   ).toFixed(2);
      // });
      // that.selectPackageCard.forEach(function(item) {
      //   item.noLargess.forEach(function(noLargess) {
      //     if (noLargess.isCardType == 2) {
      //       savingCardPrice = (
      //         parseFloat(savingCardPrice) + parseFloat(noLargess.PayAmount)
      //       ).toFixed(2);
      //     }
      //   });
      // });
      PayAmount = (
        parseFloat(that.PayAmount) -
        parseFloat(that.savingCardPrice) +
        parseFloat(that.PayCashAmount)
      ).toFixed(2);
      if (item.checked) {
        if (parseFloat(item.TotalBalance) < parseFloat(PayAmount)) {
          item.TotalAmount = item.TotalBalance;
        } else {
          item.TotalAmount = PayAmount;
        }
        item.TotalPrice = (item.TotalBalance - item.TotalAmount).toFixed(2);
      } else {
        item.TotalAmount = "";
        item.TotalPrice = item.TotalBalance;
      }
      item.cardDeductionAmount = item.TotalAmount;
      that.savingDeductionPriceAll();
    },
    // 储值卡抵扣金额变化（通用）
    savingPriceAllChange: function (item) {
      var that = this;
      // var savingCardPrice = 0;
      var PayAmount = 0;
      // that.selectSavingCard.forEach(function(item) {
      //   savingCardPrice = (
      //     parseFloat(savingCardPrice) + parseFloat(item.PayAmount)
      //   ).toFixed(2);
      // });
      // that.selectPackageCard.forEach(function(item) {
      //   item.noLargess.forEach(function(noLargess) {
      //     if (noLargess.isCardType == 2) {
      //       savingCardPrice = (
      //         parseFloat(savingCardPrice) + parseFloat(noLargess.PayAmount)
      //       ).toFixed(2);
      //     }
      //   });
      // });
      PayAmount = (
        parseFloat(that.PayAmount) -
        parseFloat(that.savingCardPrice) +
        parseFloat(item.cardDeductionAmount) +
        parseFloat(that.PayCashAmount)
      ).toFixed(2);
      if (parseFloat(item.TotalBalance) < parseFloat(PayAmount)) {
        if (parseFloat(item.TotalAmount) > parseFloat(item.TotalBalance)) {
          item.TotalAmount = item.TotalBalance;
          that.$message.error("卡扣金额不能大于" + item.TotalBalance + "元");
        }
      } else {
        if (parseFloat(item.TotalAmount) > parseFloat(PayAmount)) {
          item.TotalAmount = PayAmount;
          that.$message.error("卡扣金额不能大于" + PayAmount + "元");
        }
      }
      item.cardDeductionAmount = item.TotalAmount;
      item.TotalPrice = item.TotalBalance - item.TotalAmount;
      that.savingDeductionPriceAll();
    },
    // 储值卡抵扣金额(通用)
    savingDeductionPriceAll: function () {
      var that = this;
      var cardDeductionAmount = 0;
      var savingCardAllGoods = Enumerable.from(that.savingCardAllGoods)
        .where(function (i) {
          return i.checked;
        })
        .toArray();
      savingCardAllGoods.forEach(function (item) {
        cardDeductionAmount = (
          parseFloat(cardDeductionAmount) + (parseFloat(item.TotalAmount) || 0)
        ).toFixed(2);
      });
      that.cardDeductionAmount = cardDeductionAmount;
      that.payAmountData();
    },
    // 储值卡抵扣确认(通用)
    // submitSavingCardAll: function() {
    //   var that = this;
    //   that.SavingCardDeduction = Enumerable.from(that.savingCardAllGoods)
    //     .where(function(i) {
    //       return i.checked;
    //     })
    //     .toArray();
    //   that.payAmountData();
    //   that.dialogDeductionAll = false;
    // },
    // 数量
    numberChange: function (row) {
      var that = this;
      row.discount = 100;
      row.discountPrice = 0;
      row.ArrearAmount = 0;
      if (row.type != 2) {
        that.deductionReset(row);
        row.savingCardDeduction = [];
        row.Amount = ((row.Price * row.number * row.discount) / 100).toFixed(2);
        row.totalPrice = (
          (row.Price * row.number * row.discount) /
          100
        ).toFixed(2);
        that.payPriceData(row);
      } else {
        row.LargessPrice = (row.largessPrice * row.number).toFixed(2);
        row.Amount = (row.Price * row.number).toFixed(2);
        row.totalPrice = row.Amount;
        row.PayAmount = row.Amount;
      }

      if (row.type == 5) {
        row.noLargess.forEach(function (item) {
          item.number = row.number;
          item.totalAmount = (row.number * item.TotalPrice).toFixed(2);
          item.TotalAmount = item.totalAmount;
          item.cardDeductionAmount = 0;
          item.cardDiscountPrice = 0;
          item.ArrearAmount = "";
          item.PayAmount = (row.number * item.TotalPrice).toFixed(2);
        });
        row.largess.forEach(function (item) {
          item.number = row.number;
        });
      }
      that.payAmountData();
    },
    // 赠送
    largessChange: function (row) {
      var that = this;
      if (row.IsLargess) {
        row.discount = 100;
        row.discountPrice = 0;
        that.deductionReset(row);
        row.savingCardDeduction = [];
        row.Amount = ((row.Price * row.number * row.discount) / 100).toFixed(2);
        row.TotalAmount = row.Amount;
        row.ArrearAmount = 0;
        row.PayAmount = 0;
        row.DeductionProjectAmount = 0;
        row.discountPrice = 0;
        row.CardDeductionAmount = 0;
        (row.CardDiscountPrice = 0), (row.totalPrice = row.Amount);

        if (row.type == 2) {
          row.LargessPrice = (row.largessPrice * row.number).toFixed(2);
        }
        if (row.type == 5) {
          row.noLargess.forEach(function (item) {
            item.number = row.number;
            item.totalAmount = (row.number * item.TotalPrice).toFixed(2);
            item.cardDeductionAmount = 0;
            item.cardDiscountPrice = 0;
            item.ArrearAmount = "";
            item.PayAmount = 0;
          });
        }
      } else {
        row.PayAmount = ((row.Price * row.number * row.discount) / 100).toFixed(
          2
        );
        if (row.type == 5) {
          row.noLargess.forEach(function (item) {
            item.number = row.number;
            item.totalAmount = (row.number * item.TotalPrice).toFixed(2);
            item.cardDeductionAmount = 0;
            item.cardDiscountPrice = 0;
            item.ArrearAmount = "";
            item.PayAmount = item.totalAmount;
          });
        }
      }

      that.payAmountData();
    },
    // 储值卡金额变化
    savingAmountChange: function (row) {
      var that = this;
      row.ArrearAmount = 0;
      row.Amount = row.Amount - 0;
      row.PayAmount = (row.Amount - row.ArrearAmount).toFixed(2);
      that.payAmountData();
    },

    payPriceData: function (item) {
      if (item.type == 2) {
        item.PayAmount = (item.Amount - (item.ArrearAmount || 0)).toFixed(2);
      } else {
        item.DeductionProjectAmount = (
          parseFloat(item.discountPrice) + parseFloat(item.CardDiscountPrice)
        ).toFixed(2);
        item.PayAmount = (
          item.totalPrice -
          item.DeductionProjectAmount -
          item.CardDeductionAmount -
          item.ArrearAmount
        ).toFixed(2);
        item.TotalAmount = (item.Amount - item.CardDiscountPrice).toFixed(2);
      }
    },
    // 卡抵扣重置
    deductionReset: function (row) {
      var that = this;
      row.CardDeductionAmount = 0;
      row.CardDiscountPrice = 0;
      that.savingCardSomeGoods.forEach(function (item) {
        row.savingCardDeduction.forEach(function (sav) {
          if (item.ID == sav.ID) {
            item.TotalBalance = (
              parseFloat(item.TotalBalance) + parseFloat(sav.TotalAmount)
            ).toFixed(2);
          }
        });
      });
    },
    // 删除会员卡抵扣重置
    deductionAllReset: function () {
      var that = this;
      that.selectProject.forEach(function (item) {
        item.CardDeductionAmount = 0;
        item.CardDiscountPrice = 0;
        item.DeductionProjectAmount = 0;
        item.discountPrice = 0;
        item.discount = 100;
        item.Amount = (item.number * item.Price).toFixed(2);
        item.totalPrice = item.Amount;
        item.TotalAmount = item.Amount;
        item.ArrearAmount = 0;
        item.PayAmount = item.Amount;
        item.IsLargess = false;
        item.savingCardDeduction = [];
      });

      that.selectProduct.forEach(function (item) {
        item.CardDeductionAmount = 0;
        item.CardDiscountPrice = 0;
        item.DeductionProjectAmount = 0;
        item.discountPrice = 0;
        item.discount = 100;
        item.Amount = (item.number * item.Price).toFixed(2);
        item.totalPrice = item.Amount;
        item.TotalAmount = item.Amount;
        item.ArrearAmount = 0;
        item.PayAmount = item.Amount;
        item.IsLargess = false;
        item.savingCardDeduction = [];
      });
    },

    // 计算总金额
    payAmountData: function () {
      var that = this;
      var payAmount = 0;
      var TotalAmount = 0; //订单金额
      var ArrearAmount = 0; //欠款金额
      var PricePreferentialAmount = 0;
      var CardPreferentialAmount = 0;
      var CardDeductionAmount = 0;
      that.selectProject.forEach(function (item) {
        if (!item.IsLargess) {
          payAmount = (
            parseFloat(payAmount) + parseFloat(item.PayAmount)
          ).toFixed(2);
          ArrearAmount = (
            parseFloat(ArrearAmount) + parseFloat(item.ArrearAmount)
          ).toFixed(2);
          TotalAmount = (
            parseFloat(TotalAmount) + parseFloat(item.TotalAmount)
          ).toFixed(2);
          PricePreferentialAmount = (
            parseFloat(PricePreferentialAmount) + parseFloat(item.discountPrice)
          ).toFixed(2);
          CardPreferentialAmount = (
            parseFloat(CardPreferentialAmount) +
            parseFloat(item.CardDiscountPrice)
          ).toFixed(2);
          CardDeductionAmount = (
            parseFloat(CardDeductionAmount) +
            parseFloat(item.CardDeductionAmount)
          ).toFixed(2);
        }
      });

      that.selectProduct.forEach(function (item) {
        if (!item.IsLargess) {
          payAmount = (
            parseFloat(payAmount) + parseFloat(item.PayAmount)
          ).toFixed(2);
          ArrearAmount = (
            parseFloat(ArrearAmount) + parseFloat(item.ArrearAmount)
          ).toFixed(2);
          TotalAmount = (
            parseFloat(TotalAmount) + parseFloat(item.TotalAmount)
          ).toFixed(2);
          PricePreferentialAmount = (
            parseFloat(PricePreferentialAmount) + parseFloat(item.discountPrice)
          ).toFixed(2);
          CardPreferentialAmount = (
            parseFloat(CardPreferentialAmount) +
            parseFloat(item.CardDiscountPrice)
          ).toFixed(2);
          CardDeductionAmount = (
            parseFloat(CardDeductionAmount) +
            parseFloat(item.CardDeductionAmount)
          ).toFixed(2);
        }
      });
      that.Amount = TotalAmount;
      that.PayAmount = (payAmount - that.cardDeductionAmount).toFixed(2);
      that.ArrearAmount = ArrearAmount;
      that.PricePreferentialAmount = PricePreferentialAmount;
      that.CardPreferentialAmount = CardPreferentialAmount;
      that.CardDeductionAmount = CardDeductionAmount;
      that.payList = [{ PayMethodID: "", Amount: "", price: 0 }];
      that.PayCashAmount = 0;
      that.payTotalPrice = that.PayAmount;
      //计算购买储值卡金额，用于结账储值卡不可抵扣储值卡
      that.savingCardPrice = 0;
    },
    // 结账
    billClick: function () {
      var that = this;
      if ((that.selectProject.length <= 0) & (that.selectProduct.length <= 0)) {
        that.$message.error("请选择商品");
        return;
      }
      if (that.getBillDate() == null) {
        that.$message.error("请输入补录日期");
        return;
      }
      that.dialogBill = true;

      that.savingCardAllGoods = Enumerable.from(that.savingCardAllGoods)
        .select((val) => ({
          checked: false,
          AccountID: val.AccountID,
          Balance: val.Balance,
          ID: val.ID,
          LargessBalance: val.LargessBalance,
          SavingCardName: val.SavingCardName,
          TotalBalance: val.TotalBalance,
          TotalPrice: val.TotalBalance,
          Type: val.Type,
          TotalAmount: "",
        }))
        .toArray();
      that.savingDeductionPriceAll();
      that.payAmountData();
    },
    // 支付方式支付金额变化
    payPriceChange: function (item) {
      var that = this;
      var payAmount = (
        parseFloat(that.PayAmount) + parseFloat(item.price)
      ).toFixed(2);
      if (parseFloat(item.Amount) > parseFloat(payAmount)) {
        item.Amount = payAmount;
      }
      item.price = item.Amount;
      that.paymentAmountData();
    },
    payMethodChange: function (item) {
      var that = this;
      if (item.PayMethodID == "") {
        item.Amount = "";
      } else {
        if (item.Amount == "") {
          item.Amount = parseFloat(that.PayAmount).toFixed(2);
        }
      }
      that.payPriceChange(item);
    },
    // 支付方式金额总计
    paymentAmountData: function () {
      var that = this;
      var amount = 0;
      that.payList.forEach(function (item) {
        amount = (parseFloat(amount) + (parseFloat(item.price) || 0)).toFixed(
          2
        );
      });
      that.PayCashAmount = amount;
      that.PayAmount = (that.payTotalPrice - amount).toFixed(2);
    },
    // 删除支付
    removePayClick: function (index) {
      var that = this;
      that.payList.splice(index, 1);
      that.paymentAmountData();
    },
    // 添加支付
    addPayclick: function () {
      var that = this;
      that.payList.push({ PayMethodID: "", Amount: "", price: 0 });
    },

    // 确认结帐
    submitPayClick: function () {
      var that = this;
      that.modalLoading = true;
      if (that.PayAmount != 0) {
        that.$message.error({
          message: "请填写收款金额。",
          duration: 2000,
        });
        that.modalLoading = false;
        return;
      }
      that.selectProject.forEach(function (item) {
        item.ProjectSaleHandler = [];
        item.ProjectTreatHandler = [];
        item.projectSavingCardDeduction = [];

        item.saleHandlerList.forEach(function (handler) {
          handler.Employee.forEach(function (employee) {
            item.ProjectSaleHandler.push({
              SaleHandlerID: employee.SaleHandlerID,
              EmployeeID: employee.EmployeeID,
              Scale: employee.Discount,
            });
          });
        });

        item.consumeHandlerList.forEach(function (handler) {
          handler.Employee.forEach(function (employee) {
            item.ProjectTreatHandler.push({
              TreatHandlerID: employee.TreatHandlerID,
              EmployeeID: employee.EmployeeID,
              Scale: employee.Discount,
            });
          });
        });

        item.projectSavingCardDeduction = Enumerable.from(
          item.savingCardDeduction
        )
          .select((val) => ({
            SavingCardAccountID: val.AccountID,
            DeductionAmount: val.TotalAmount,
            PreferentialAmount: val.cardDiscountPrice,
            Type: val.Type,
            ID: val.ID,
          }))
          .toArray();
      });

      that.selectProduct.forEach(function (item) {
        item.ProductSaleHandler = [];
        item.ProductTreatHandler = [];
        item.productSavingCardDeduction = [];
        item.saleHandlerList.forEach(function (handler) {
          handler.Employee.forEach(function (employee) {
            item.ProductSaleHandler.push({
              SaleHandlerID: employee.SaleHandlerID,
              EmployeeID: employee.EmployeeID,
              Scale: employee.Discount,
            });
          });
        });

        item.consumeHandlerList.forEach(function (handler) {
          handler.Employee.forEach(function (employee) {
            item.ProductTreatHandler.push({
              TreatHandlerID: employee.TreatHandlerID,
              EmployeeID: employee.EmployeeID,
              Scale: employee.Discount,
            });
          });
        });

        item.productSavingCardDeduction = Enumerable.from(
          item.savingCardDeduction
        )
          .select((val) => ({
            SavingCardAccountID: val.AccountID,
            DeductionAmount: val.TotalAmount,
            PreferentialAmount: val.cardDiscountPrice,
            Type: val.Type,
            ID: val.ID,
          }))
          .toArray();
      });

      var Project = Enumerable.from(that.selectProject)
        .select((val) => ({
          ProjectID: val.ID,
          Quantity: val.number,
          Price: val.Price,
          TotalAmount: val.TotalAmount,
          PayAmount: val.PayAmount,
          ArrearAmount: val.ArrearAmount,
          SavingCardDeductionAmount: val.CardDeductionAmount,
          PricePreferentialAmount: val.discountPrice,
          CardPreferentialAmount: val.CardDiscountPrice,
          IsLargess: val.IsLargess,
          ProjectSaleHandler: val.ProjectSaleHandler,
          ProjectTreatHandler: val.ProjectTreatHandler,
          SavingCardDeduction: val.projectSavingCardDeduction,
        }))
        .toArray();

      var Product = Enumerable.from(that.selectProduct)
        .select((val) => ({
          ProductID: val.ID,
          Quantity: val.number,
          Price: val.Price,
          TotalAmount: val.TotalAmount,
          PayAmount: val.PayAmount,
          ArrearAmount: val.ArrearAmount,
          SavingCardDeductionAmount: val.CardDeductionAmount,
          PricePreferentialAmount: val.discountPrice,
          CardPreferentialAmount: val.CardDiscountPrice,
          IsLargess: val.IsLargess,
          ProductSaleHandler: val.ProductSaleHandler,
          ProductTreatHandler: val.ProductTreatHandler,
          SavingCardDeduction: val.productSavingCardDeduction,
        }))
        .toArray();

      var payList = Enumerable.from(that.payList)
        .where(function (i) {
          return i.PayMethodID != "" && i.Amount != "";
        })
        .select((val) => ({
          PayMethodID: val.PayMethodID,
          Amount: val.Amount,
        }))
        .toArray();

      that.SavingCardDeduction = Enumerable.from(that.savingCardAllGoods)
        .where(function (i) {
          return i.checked && parseFloat(i.TotalAmount) != 0;
        })
        .select((val) => ({
          Type: val.Type,
          SavingCardAccountID: val.AccountID,
          DeductionAmount: val.TotalAmount,
          ID: val.ID,
        }))
        .toArray();

      var CardDeductionAmount = (
        parseFloat(that.CardDeductionAmount) +
        parseFloat(that.cardDeductionAmount)
      ).toFixed(2);
      var params = {
        BillID: that.BillID,
        CustomerID: that.customerID,
        BillDate: that.getBillDate(),
        Amount: that.Amount,
        PayAmount: that.PayCashAmount,
        ArrearAmount: that.ArrearAmount,
        CardDeductionAmount: CardDeductionAmount,
        PricePreferentialAmount: that.PricePreferentialAmount,
        CardPreferentialAmount: that.CardPreferentialAmount,
        Remark: that.Remark,
        Project: Project,
        Product: Product,
        PayMethod: payList,
        SavingCardDeduction: that.SavingCardDeduction,
      };
      agileSellAPI
        .createAgilSaleBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.selectProject = [];
            that.selectProduct = [];

            that.orderAmount = that.Amount;
            that.Amount = 0; //订单金额
            that.PayAmount = 0; //现金支付金额
            that.payTotalPrice = 0; //待支付总金额
            that.ArrearAmount = 0; //欠款金额
            that.cardDeductionAmount = 0;
            that.CardDeductionAmount = 0; // 储值卡抵扣金额
            that.PricePreferentialAmount = 0; //手动改价优惠金额
            that.CardPreferentialAmount = 0; //卡优惠金额
             that.$parent.IsSupplement = false;
            that.$parent.BillDate = null;
            that.orderNumber = res.Data.SaleBillID;
            that.consumeOrderNumber = res.Data.TreatBillID;
            that.dialogBill = false;
            that.dialogPay = true;
            if (that.customerID != null) {
              that.savingCardAllGoodsData();
              that.savingCardSomeGoodsData();
            }
            // that.dialogPay = true;
            // that.$message.success({ message: "收款完成", duration: 3000 });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },

    /**详情 数据    */
    showTreatInfo: function (row) {
      var that = this;
      let params = {
        ID: that.consumeOrderNumber,
      };
      that.printConsumeLoading = true;
      orderAPI
        .treatBillinfo(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatInfo = res.Data;
            that.treatCashierReceiptDialogVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.printConsumeLoading = false;
          that.getReceiptConfig();
        });
    },

    /** 获取销售订单详情 */
    getOrderDetail() {
      var that = this;
      that.printSaleLoading = true;
      var params = {
        SaleBillID: that.orderNumber,
      };

      orderSellAPI
        .getOrderDetail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.saleOrderDetail = res.Data;
            that.cashierReceiptDialogVisible = true;
          }
        })
        .finally(function () {
          that.printSaleLoading = false;
        });
    },

    /**  获取小票配置信息  */
    getReceiptConfig() {
      var that = this;
      cashierAPI
        .getReceiptConfigBill()
        .then((res) => {
          if (res.StateCode == 200) {
            that.cashierReceipt = res.Data;
          }
        })
        .finally(() => {});
    },

    /**  获取 消耗打印内容  */
    printTreatBillContent() {
      let that = this;
      let params = {
        ID: that.treatInfo.ID,
      };
      cashierAPI
        .printTreatBillContent(params)
        .then((res) => {
          if (res.StateCode == 200) {
            for (let index = 0; index < res.Data.copies; index++) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          }
        })
        .finally(() => {});
    },

    /**  获取 销售 打印内容  */
    getprintSaleBillContent() {
      let that = this;
      let params = {
        SaleBillID: that.saleOrderDetail.ID,
      };
      cashierAPI
        .printSaleBillContent(params)
        .then((res) => {
          if (res.StateCode == 200) {
            for (let index = 0; index < res.Data.copies; index++) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          }
        })
        .finally(() => {});
    },
    /**  关闭结账成功弹窗清除 备注信息  */
    closeSucceedDialog() {
      this.Remark = "";
    },

    // 姓名隐藏
    formatName(name) {
      return printReceipt.hiddenName(name);
    },
    // 手机号隐藏
    formatPhone(phone) {
      return printReceipt.hiddenPhone(phone);
    },
    // 获取 当前卡项的序号
    getCurrentCardLength(savingCards, index, chileIndex) {
      let number = chileIndex + 1;
      for (let i = 0; i < savingCards.length; i++) {
        const element = savingCards[i];
        if (i < index) {
          number += element.Project.length;
        }
      }
      return number;
    },

    /**  卡项中项目总长度  */
    getCardTotalLength(Cards) {
      let number = 0;
      for (let i = 0; i < Cards.length; i++) {
        const element = Cards[i];
        number += element.Project.length;
      }
      return number;
    },
    /**  销售 订单详情 */
    printSellOrderReceipt() {
      var that = this;
      that.getReceiptConfig();
    },
    /**  消耗 消耗 */
    printConsumeOrderReceipt() {
      var that = this;
    },
  },
  mounted() {
    var that = this;
    if (localStorage.getItem("access-user")) {
      that.userName = JSON.parse(
        localStorage.getItem("access-user")
      ).EmployeeName;
      that.entityName = JSON.parse(
        localStorage.getItem("access-user")
      ).EntityName;
    }
    var that = this;
    socket = printReceipt.getSocket((res) => {
      if (res.status == "success") {
        that.$message.success({
          message: "打印成功",
          duration: 2000,
        });
        that.treatCashierReceiptDialogVisible = false;
        that.cashierReceiptDialogVisible = false;
      }
    });

    that.projectData();
    that.productData();

    that.goodsDataInit();

    that.projectHandlerData();
    that.productHandlerData();
    that.treatProjectHandlerNet();
    that.treatProductHandlerNet();

    that.salePayMethodData();
    if (that.customerID != null) {
      that.savingCardAllGoodsData();
      that.savingCardSomeGoodsData();
    }
  },
};
</script>

<style lang="scss">
.agileSell {
  .sale_content {
    font-size: 13px;
    height: 100%;
    .project_left {
      border-right: 1px solid #eee;
      height: 100%;
      color: #303133;
      .el-tabs__content {
        div {
          line-height: 23px;
        }
      }
      .el-tabs {
        height: calc(100% - 62px);
        .el-tabs__header {
          margin: 0;
          .el-tabs__nav-scroll {
            // padding-left: 15px;
            margin-left: 15px;
          }
        }
        .el-tabs__content {
          height: calc(100% - 40px);
          .el-tab-pane {
            height: 100%;
            .category_project {
              height: 100%;
              .category {
                height: 100%;
                .category_select {
                  color: #039be5;
                }
              }
              .project {
                height: 100%;
                overflow: auto;
                .el-collapse {
                  .el-collapse-item__header {
                    background-color: #f5f7fa !important;
                    padding: 0 10px;
                  }
                  .el-collapse-item__content {
                    padding: 0;
                  }
                }
                .category_sub_list {
                  overflow-x: auto;
                  .category_sub_select {
                    color: #039be5;
                  }
                }
                .project_list {
                  /*height: calc(100% - 57px);*/
                  height: 100%;
                }
                .producct_list {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .project_right {
      height: 100%;
      .el-main {
        padding: 0;
        .row_header {
          background-color: #ecf8ff;
          padding: 10px;
        }
        .employee_num {
          width: 90px;
          .el-input-group__append {
            padding: 0 10px;
          }
        }
        .el-form-item__label {
          font-size: 13px !important;
        }
      }
      .el-footer {
        height: initial !important;
        padding: 10px;
      }
    }
  }

  .el-icon-sort {
    color: #666;
    font-size: 14px;
    transform: rotate(90deg);
  }

  .dialog_bill_detail {
    background-color: #ecf8ff;
    padding: 15px;
    border-radius: 5px;
  }

  .el-scrollbar_height {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .el-scrollbar_x_width {
    .el-scrollbar__wrap {
      .el-scrollbar__view {
        white-space: nowrap;
        .el-menu-item {
          display: inline-block;
        }
        .is-active {
          a {
            color: #409eff;
          }
        }
      }
    }
  }

  .el-input__inner {
    padding: 0 0 0 10px;
  }

  .saving_discount {
    .el-table__row {
      background-color: #f5f7fa;
    }
  }

  .savingCardAmount {
    .el-input__inner {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  .savingCardLargessAmount {
    .el-input__inner {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  .el-divider {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .sell-el-divider {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>