/**
 * Created by preference on 2020/10/26
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /**   */
  createAgilSaleBill: params => {
    return API.POST('api/fastSaleBill/create', params)
  },
  /**   */
  getAgileSaleGoods: params => {
    return API.POST('api/fastSaleGoods/all', params)
  },
}